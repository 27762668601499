<template>
  <v-container style="max-height: 90vh; overflow-y: auto;">
    <v-row no-gutters>
      <v-col cols="12" class="my-6">
        <h3 class="font-weight-bold">{{$t('view.history.title')}}</h3>
      </v-col>

      <v-col cols="12" v-if="!historyLoaded">
        <Skeleton
          :occurrence="3"
          type="list-item" />
      </v-col>

      <v-col cols="12" v-else>
        <p v-if="history.length === 0">{{$t('view.history.no-result')}}</p>
        <v-card class="my-2" style="border-radius: 10px !important;">
          <v-list>
            <template v-for="(el) of history">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary" size="20">far fa-search</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="cursor: pointer !important;" @click="goTo(el)">{{el.addressTo}}</v-list-item-title>
                  <v-list-item-subtitle>{{$moment(el.date).fromNow()}}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-menu
                    :nudge-width="200"
                    offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon elevation="2" class="mx-1" v-bind="attrs" v-on="on">
                        <v-icon>far fa-ellipsis-h</v-icon>
                      </v-btn>
                    </template>

                    <v-card style="border-radius: 10px !important;">
                      <v-list>
                        <v-list-item @click="itineraryFrom(el)">{{$t('view.history.buttons.itineraryFrom')}}</v-list-item>
                        <v-list-item @click="itineraryTo(el)">{{$t('view.history.buttons.itineraryTo')}}</v-list-item>
                        <v-list-item @click="deleteSearchHistory(el._id)" style="color: red !important;">{{$t('view.history.buttons.delete')}}</v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

  name: "MyHistory",

  components: {
    Skeleton: () => import("@/components/Common/Skeleton"),
    MyHistoryCard: () => import("@/components/Account/MyHistoryCard")
  },

  data() {
    return {
      historyLoaded: false,
      history: [],
    }
  },

  created() {
    this.getHistory();
    this.$store.commit("pageHead/setTitle", this.$t('view.history.pageTitle'))
  },

  methods: {
    getHistory() {
      this.historyLoaded = false;
      this.$http
      .get(`/map/users/${this.$session.get('id')}/search-history?limit=20&sortBy=meta.created.date&sortDesc=true`, {
        headers: {
          Authorization: `Bearer ${this.$session.get('jwt')}`
        }
      })
      .then((res) => {
        this.history = res.data.items;
      })
      .catch(err => {
        this.$store.commit("alert/showError", this.$t('view.history.errors.NOT_LOADED'));
      })
      .finally(() => {
        this.historyLoaded = true;
      })
    },

    itineraryFrom(data) {
      this.$router.replace({name: "Itinerary", query:  {...this.$route.query, from: `${data.coordinatesTo.lat},${data.coordinatesTo.lng}`, fromLabel: data.addressTo}});
    },

    itineraryTo(data) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.$router.push({name: "Itinerary", query: {...this.$route.query, fromLabel: "Ma position", from: `${position.coords.latitude},${position.coords.longitude}`, toLabel: data.addressTo, to: `${data.coordinatesTo.lat},${data.coordinatesTo.lng}`}});
      }, (error) => {
        this.$router.push({name: "Itinerary", query: {...this.$route.query, toLabel: data.addressTo, to: `${data.coordinatesTo.lat},${data.coordinatesTo.lng}`}});
      });
    },

    deleteSearchHistory(id) {
      this.$http
        .delete(`/map/users/${this.$session.get('id')}/search-history/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$session.get('jwt')}`
          }
        })
        .then((res) => {
          this.$store.commit("alert/showSuccess", this.$t('view.history.success_deleted'));
          this.$emit("delete");
        })
        .catch(err => {
          this.$store.commit("alert/showErrorAxios", err);
        })
    },

    goTo(data) {
      if (data && data.coordinatesTo.lat) {
        this.$router.replace({
          query: {...this.$route.query, coords: `${data.coordinatesTo.lat},${data.coordinatesTo.lng}`, zoom: 13}
        })
      }
    }
  }
}
</script>

<style scoped>

</style>